import { DateTime } from 'luxon';

import { OrderWarehouseDataItem } from '@apis/trackOrders/TrackOrdersApi.types';
import { Account } from '@apis/users/UsersApi.types';
import { orderDateFilterTypesData } from '@constants/trackOrdersMockedData';
import { SORTING_DIRECTION } from '@customTypes/general/general.types';
import { OrdersListSearchParams } from '@customTypes/mirAndTracking/MirAndTracking.types';
import { toInitialUrlSearchParamsHelper } from '@helpers/paramsHandler';
import { getSubAccountIdBySubAccNumber } from '@helpers/treeHelper';

const defaultSortingConfig = {
    field: '',
    direction: SORTING_DIRECTION.DESC
};

export const toUrlSearchParams = ({
    page,
    pageSize,
    orderTypes,
    sorts,
    sortingInitialState = defaultSortingConfig,
    dateFrom,
    dateTo,
    dateType,
    search,
    status,
    accountsAndSubs,
    originLocations,
    destinationLocations,
    partConditionTypes,
    deliveryTypes,
    showDangerousGoods,
    showWatchList,
    transportTypes
}: OrdersListSearchParams = {}): Partial<
    Record<keyof OrdersListSearchParams, string>
> => {
    const result: ReturnType<typeof toUrlSearchParams> = {};

    const initialParams = {
        sorts,
        sortingInitialState,
        page,
        pageSize,
        search,
        status,
        accountsAndSubs,
        originLocations,
        destinationLocations,
        partConditionTypes,
        deliveryTypes,
        showDangerousGoods,
        transportTypes
    };

    toInitialUrlSearchParamsHelper(result, initialParams);

    if (orderTypes) {
        result.orderTypes = orderTypes;
    }

    if (transportTypes) {
        result.transportTypes = transportTypes;
    }

    if (dateFrom) {
        result.dateFrom = dateFrom;
    }

    if (dateTo) {
        result.dateTo = dateTo;
    }

    if (dateType) {
        result.dateType = dateType;
    }

    if (originLocations) {
        result.originLocations = originLocations;
    }

    if (destinationLocations) {
        result.destinationLocations = destinationLocations;
    }

    if (partConditionTypes) {
        result.partConditionTypes = partConditionTypes;
    }

    if (deliveryTypes) {
        result.deliveryTypes = deliveryTypes;
    }

    if (showDangerousGoods) {
        result.showDangerousGoods = showDangerousGoods;
    }

    if (showWatchList) {
        result.showWatchList = showWatchList;
    }

    return result;
};

export interface DateFilterData {
    filterData: string;
    from: string;
    to: string;
    dateType: string;
    defaultDateRangeDays: number;
    defaultFrom: string;
    defaultTo: string;
}

interface DateFilterDataProps {
    defaultDateRangeDays?: number;
    dateFrom?: string;
    dateTo?: string;
    dateType?: string;
}

export interface CheckDefaultPeriodProps {
    defaultDateRangeDays: number;
    from?: string;
    to?: string;
    dateType?: string;
}

export const getDateFilterData = ({
    defaultDateRangeDays = 14,
    dateFrom,
    dateTo,
    dateType = orderDateFilterTypesData[0].value
}: DateFilterDataProps): DateFilterData => {
    const defaultDateAgo = DateTime.now()
        .minus({ days: defaultDateRangeDays - 1 })
        .toISODate();
    const today = DateTime.now().toISODate();
    const chosenDateFromOrDefault = dateFrom || defaultDateAgo;
    const chosenDateToOrCurrent = dateTo || today;

    const filterData = `${dateType}>=${chosenDateFromOrDefault},${dateType}<=${chosenDateToOrCurrent}`;

    return {
        filterData,
        from: dateFrom || defaultDateAgo,
        to: dateTo || today,
        dateType,
        defaultDateRangeDays,
        defaultFrom: defaultDateAgo,
        defaultTo: today
    };
};

export const getSearchData = (searchString?: string) => {
    return searchString
        ? `ProNumberOrAwbOrReferenceNumber==${searchString}`
        : '';
};

export const getPlainArrayData = (typesElem: string[], filterName: string) => {
    return typesElem?.length ? `${filterName}==${typesElem}` : '';
};

export const getFilterTypes = (types?: string[], filterName?: string) => {
    return types?.length ? `${filterName}==${types.join('|')}` : '';
};

export enum WAREHOUSE_FILTER_PREFIX {
    DESTINATION = 'Destination',
    ORIGIN = 'Origin'
}

export const getWarehousesFilter = (
    filterPrefix: WAREHOUSE_FILTER_PREFIX,
    selectedKeys: string[] = [],
    data?: OrderWarehouseDataItem
) => {
    if (!selectedKeys.length || !data?.availableSslList?.length) {
        return '';
    }

    const regionCodes = new Set();
    const countryCodes = new Set();
    const warehouseCodes = new Set();

    data.availableSslList.forEach((region) => {
        const regionKey = region.regionCode;
        const regionMatch = selectedKeys.some((key) => key === regionKey);

        if (regionMatch) {
            regionCodes.add(regionKey);
        }

        region.availableCountries.forEach((country) => {
            const countryKey = `${regionKey}_${country.countryCode}`;
            const countryMatch = selectedKeys.some((key) => key === countryKey);

            if (countryMatch) {
                countryCodes.add(country.countryCode);
            }

            country.sslList.forEach((warehouse) => {
                const warehouseKey = `${countryKey}_${warehouse.code}`;
                const warehouseMatch = selectedKeys.some(
                    (key) => key === warehouseKey
                );

                if (warehouseMatch) {
                    warehouseCodes.add(warehouse.code);
                }
            });
        });
    });

    const result = [];
    if (regionCodes.size > 0) {
        result.push(`${filterPrefix}RegionCode==${[...regionCodes].join('|')}`);
    }
    if (countryCodes.size > 0) {
        result.push(
            `${filterPrefix}CountryCode==${[...countryCodes].join('|')}`
        );
    }
    if (warehouseCodes.size > 0) {
        result.push(
            `${filterPrefix}WarehouseCode==${[...warehouseCodes].join('|')}`
        );
    }

    return result.join(',');
};

export const getWarehousesFromUrl = (
    filterPrefix: WAREHOUSE_FILTER_PREFIX,
    filters = '',
    data?: OrderWarehouseDataItem
): string[] => {
    if (!filters.length || !data?.availableSslList?.length) {
        return [];
    }

    const regionCodes = new Set();
    const countryCodes = new Set();
    const warehouseCodes = new Set();

    filters.split(',').forEach((filter) => {
        const [key, value] = filter
            .split('==')
            .map((product) => product.trim());
        const codes = value.split('|').map((code) => code.trim());

        switch (key) {
            case `${filterPrefix}RegionCode`:
                codes.forEach((code) => regionCodes.add(code));
                break;
            case `${filterPrefix}CountryCode`:
                codes.forEach((code) => countryCodes.add(code));
                break;
            case `${filterPrefix}WarehouseCode`:
                codes.forEach((code) => warehouseCodes.add(code));
                break;
        }
    });

    const formattedResults = new Set<string>();

    data.availableSslList.forEach((region) => {
        if (regionCodes.has(region.regionCode)) {
            formattedResults.add(region.regionCode);
        }

        region.availableCountries.forEach((country) => {
            const regionCountry = `${region.regionCode}_${country.countryCode}`;

            if (countryCodes.has(country.countryCode)) {
                formattedResults.add(regionCountry);
            }

            country.sslList.forEach((warehouse) => {
                if (warehouseCodes.has(warehouse.code)) {
                    formattedResults.add(`${regionCountry}_${warehouse.code}`);
                }
            });
        });
    });

    return Array.from(formattedResults);
};

export const getAccountsAndSubAccountsFilter = (
    accountsAndSubs?: string[],
    accounts?: Account[]
): string => {
    if (!accounts?.length || !accountsAndSubs?.length) {
        return '';
    }

    const accountsMap = new Map(accounts.map((acc) => [acc.accountId, acc]));
    const result = new Map();

    for (const item of accountsAndSubs) {
        const [accId, subAccId] = item.split('-').map(Number);
        const account = accountsMap.get(accId);

        if (account) {
            if (isNaN(subAccId)) {
                result.set(account.accountNumber, []);
            } else {
                const subAccount = account.subAccounts.find(
                    (sub) => sub.subAccountId === subAccId
                );

                if (subAccount) {
                    if (!result.has(account.accountNumber)) {
                        result.set(account.accountNumber, []);
                    }
                    result
                        .get(account.accountNumber)
                        .push(subAccount.subAccountNumber);
                }
            }
        }
    }

    const output = Array.from(result)
        .map(([acc, subAccs]) => {
            return subAccs.length > 0 ? `${acc}:${subAccs.join(';')}` : acc;
        })
        .join('|');

    return `AccountAndSubAccounts==${output}`;
};

export const getTransportTypesFilter = (transportTypes?: string[]) => {
    if (!transportTypes) return '';

    return transportTypes.join(',');
};

export const getAccountsAndSubsFromUrl = (
    accounts?: Account[],
    accountsAndSubsString = ''
): string[] => {
    const result = [];

    if (accounts?.length && accountsAndSubsString) {
        const data = accountsAndSubsString.split('==')?.[1]?.split('|');

        for (const item of data) {
            const [accNumber, subs] = item.split(':');
            const account = accounts.find(
                (item) => item.accountNumber === accNumber
            );

            if (account) {
                if (subs) {
                    const subArray = subs.split(';');

                    for (const sub of subArray) {
                        const subAccId = getSubAccountIdBySubAccNumber(
                            sub,
                            account.subAccounts
                        );
                        result.push(`${account.accountId}-${subAccId}`);
                    }
                } else {
                    result.push(`${account.accountId}`);
                }
            }
        }
    }

    return result;
};

export const getShowDangerousGoodFilter = (state?: string) => {
    return `IsDangerousGoods==${state}`;
};

export const getShowWatchListFilter = (state?: string) => {
    return `IsOnlyWatchlist==${state}`;
};

export interface GetOrderFiltersProps {
    search?: string;
    date?: string;
    status?: string;
    orderTypes?: string[];
    productConditionTypes?: string[];
    deliveryTypes?: string[];
    accountsAndSubs?: string[];
    transportTypes?: number[];
    originLocations?: string[];
    destinationLocations?: string[];
    accounts?: Account[];
    warehouses?: OrderWarehouseDataItem;
    showDangerousGoods?: string;
    showWatchList?: string;
    shippingServices?: number[];
}

export const getFilters = (
    {
        search,
        date,
        orderTypes,
        productConditionTypes,
        deliveryTypes,
        accountsAndSubs,
        originLocations,
        warehouses,
        destinationLocations,
        status,
        accounts,
        showDangerousGoods,
        showWatchList,
        shippingServices
    }: GetOrderFiltersProps = {},
    ignoreFilterDates = false,
    defaultDateRangeDays = 14
) => {
    const dateFilter = ignoreFilterDates
        ? ''
        : date || getDateFilterData({ defaultDateRangeDays }).filterData;
    const searchFilter = getSearchData(search);
    const orderTypesFilter = getFilterTypes(orderTypes, 'OrderTypes');
    const partConditionTypesFilter = getPlainArrayData(
        productConditionTypes || [],
        'ItemQuality'
    );
    const deliveryTypesFilter = getPlainArrayData(
        deliveryTypes || [],
        'OrderLocation'
    );
    const accountFilter = getAccountsAndSubAccountsFilter(
        accountsAndSubs,
        accounts
    );
    const originLocationFilter = getWarehousesFilter(
        WAREHOUSE_FILTER_PREFIX.ORIGIN,
        originLocations,
        warehouses
    );
    const destinationLocationFilter = getWarehousesFilter(
        WAREHOUSE_FILTER_PREFIX.DESTINATION,
        destinationLocations,
        warehouses
    );
    const showDangerousGoodsFilter = showDangerousGoods
        ? getShowDangerousGoodFilter(showDangerousGoods)
        : null;

    const showWatchListFilter = showWatchList
        ? getShowWatchListFilter(showWatchList)
        : null;

    const statusFilter = `OrderStatuses==${status}`;

    const shippingIds = shippingServices?.join('|');
    const shippingServicesFilter = shippingIds
        ? `ShippingServiceIds==${shippingIds}`
        : '';

    return [
        dateFilter,
        searchFilter,
        orderTypesFilter,
        partConditionTypesFilter,
        originLocationFilter,
        destinationLocationFilter,
        deliveryTypesFilter,
        accountFilter,
        statusFilter,
        showDangerousGoodsFilter,
        showWatchListFilter,
        shippingServicesFilter
    ]
        .filter(Boolean)
        .join(',');
};

export const checkIfDefaultPeriodSelected = ({
    defaultDateRangeDays = 14,
    from,
    to,
    dateType
}: CheckDefaultPeriodProps): boolean =>
    Boolean(
        getDateFilterData({ defaultDateRangeDays }).from === from &&
            getDateFilterData({ defaultDateRangeDays }).to === to &&
            getDateFilterData({ defaultDateRangeDays }).dateType === dateType
    );

export const numberOrUndefined = (input: string | null): number | undefined => {
    if (typeof input !== 'string') {
        return undefined;
    }

    const result = parseInt(input, 10);

    return isNaN(result) ? undefined : result;
};
